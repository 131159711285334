*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 15px;
}

body {
	margin: 0;
	--color-text: #fff;
    --color-bg: #000000;
    --color-link: #db4d4d;
    --color-link-hover: #666;
	--font-title: ambroise-firmin-std, serif;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: maple-web, sans-serif;
	-webkit-font-smoothing: antialiased;
}

.demo-2 {
    --color-link: #cb7939;
}

.demo-3 {
	--color-link: #43a0c8;
}

.demo-4 {
	--color-link: #cb7939;
}

canvas {
	display: block;
}

#container {
	width: 100vw;
	height: 100vh;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover,
a:focus {
	color: var(--color-link-hover);
	outline: none;
}

.frame {
	padding: 2rem 1rem;
	text-align: center;
	position: fixed;
	width: 100%;
	z-index: 1000;
}

.frame__title {
	font-size: 1rem;
	margin: 0 0 1rem;
	font-weight: 400;
}

.frame__links {
	display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
	margin-right: 1rem;
}

.frame__demos {
	margin: 1rem 0;
}

.frame__demos-text {
	margin-right: 1.5rem;
}

.frame__demo--current,
.frame__demo--current:hover {
	color: var(--color-link-hover);
}

@media screen and (min-width: 53em) {
	.frame {
		text-align: left;
		z-index: 100;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 1.5rem;
		pointer-events: none;
		grid-gap: 3rem;
		grid-template-columns: auto auto 1fr;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links ...'
							'... ... ...'
							'... demos demos';
	}
	.frame__title-wrap {
		grid-area: title;
		display: flex;
	}
	.frame__title {
		margin: 0;
	}
	.frame__tagline {
		position: relative;
		margin: 0 0 0 1rem;
		padding: 0 0 0 1rem;
		opacity: 0.5;
	}
	.frame__demos {
		margin: 0;
		grid-area: demos;
		justify-self: end;
	}
	.frame__links {
		grid-area: links;
		padding: 0;
		justify-self: end;
	}
	.frame a {
		pointer-events: auto;
	}
}